<template>
  <div class='full-width d-flex justify-between align-center'>
    <EmailScreen
      v-if='activeStep === 1'
      :email='form.email'
      flow-type='login'
      @update='handleUpdate'
      @complete='activeStep = 2'
    />
    <ConfirmOtpScreen
      v-if='activeStep === 2'
      :email='form.email'
      @back='activeStep = 1'
      @complete='activeStep = 3'
    />
    <DownloadAppScreen v-if='activeStep === 3'>
      <div class='auth-back'>
        <a href='#' @click.prevent='resetCurrentStep'>
          <Icon name='chevron-left' />
          <span>
            {{ $t('back') }}
          </span>
        </a>
      </div>
      <div class='auth-title'>
        {{ $t('signIn') }}
      </div>
      <div class='auth-text lh-13' v-html="$t('signInText')"></div>
    </DownloadAppScreen>
  </div>
</template>
<script setup>
import DownloadAppScreen from './components/DownloadAppScreen.vue';
import Icon from '@/components/atoms/Icon.vue';
import ConfirmOtpScreen from '@/modules/auth/components/ConfirmOtpScreen.vue';
import EmailScreen from '@/modules/auth/components/EmailScreen.vue';
import { reactive, ref } from 'vue';

const activeStep = ref(1);

const form = reactive({
  email: '',
  otp: ''
});

const handleUpdate = params => {
  Object.assign(form, params);
};

const resetCurrentStep = () => {
  activeStep.value = 1
  form.email = ''
  form.otp = ''
}
</script>
